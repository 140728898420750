import "./App.css";
import "./style.css";
import RequestPhildForm from "./Components/RequestPhildForm";



function App() {
  return (
    <div class="page">
      <div class="header header-company">
        <div class="logo">
          <a href="index.html">
            <img
              alt="UT home"
              src="https://www.unither.com/gfx/logo-paper.png"
            />
          </a>
        </div>
        <div class="nav-bar"></div>
        <div class="nav"></div>

        <div class="paper-top"></div>
      </div>
      <div class="body">
        <div class="section-header">
          <h1>Request PH-ILD Information</h1>
        </div>

        <p />
        <div class="top-bar"></div>
        <p />
        <RequestPhildForm></RequestPhildForm>
      </div>
      <div class="footer footer-company">
        <a href="https://vimeo.com/334669535">
          <div class="paper-bottom"></div>

          <div class="footer-logo"></div>
        </a>
        <ul>
          <a href="https://vimeo.com/334669535"> </a>
          <li>
            <a href="https://vimeo.com/334669535"></a>
            <a href="https://www.unither.com/contact.html">Contact Us</a>
          </li>
          {/* <li>
            <a href="https://www.unither.com/sitemap.html">Sitemap</a>
          </li> */}
          <li>
            <a
              href="http://www.unither.com/privacy/privacypolicy.pdf"
              target="_blank"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a href="https://www.unither.com/terms.html">Terms of Use</a>
          </li>
          {/* <li>
            <a href="http://pvtraining.unither.com" target="_blank">
              Training
            </a>
          </li>
          <li>
            <a href="https://unither.okta.com/login/login.htm?fromURI=%2Fapp%2FUserHome">
              Unitherians
            </a>
          </li>*/}
          <li class="footer-last">
            © 2022 United Therapeutics Corporation. All rights reserved. 
          </li>
        </ul>
        {/* <div class="footer-social">
          Follow Us! <br />
          <a href="https://www.linkedin.com/company/unitedtherapeutics">
            <img
              src="https://www.unither.com/gfx/linkedin.png"
              width="40"
              height="40"
              alt="LinkedIn"
            />
          </a>
          <a href="https://www.facebook.com/UnitedTherapeutics">
            <img
              src="https://www.unither.com/gfx/facebook.png"
              width="40"
              height="40"
              alt="Facebook"
            />
          </a>
        </div>*/}
      </div>
    </div>
  );
}

export default App;
