import logo from "./..//logo.svg";
import React, { useReducer, useState, useEffect } from "react";
import "./../App.css";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import ApprovedTaxonomies from "./../_data/ApprovedTaxonomiesList";

const recaptchaRef = React.createRef();

const formReducer = (state, event) => {
  if (event.reset) {
    return {
      firstName: "",
      LastName: "",
      PrescriberNPI: 0,
      PhoneNumber: "",
      emailAddress: "",
      Address: "",
      PrescriberFirstName: "",
      PrescriberLastName: "",
      PrescriberZipCode: 0,
      UsePresriberAddress: false,
      OfficeMaterials: false,
      NppessEntries: [],
    };
  }
  return {
    ...state,
    [event.name]: event.value,
  };
};

export default function RequestPhildForm() {
  const [formData, setFormData] = useReducer(formReducer, {});
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue.length === 0) {
    } else {
      submitToGDO();
    }
    //TODO: fill submit behavior
  };

  function submitToGDO() {
    const gdoRepUrl =
      "https://fn-gdo-api-requestarep-dev.azurewebsites.net/api/request";
    axios
      .post(
        gdoRepUrl,
        {
          rep: {
            npi: formData.PrescriberNPI,
            email: formData.emailAddress,
            phone: formData.PhoneNumber,
            firstName: formData.firstName,
            lastName: formData.LastName,
            address: formData.Address,
            addressUseNPI: formData.UsePresriberAddress,
            officeMaterialsOptIn: false
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            code: "ZsulaburZ6GNtwZhlKJF/neqpRtA9MzbUoQ36HFR3rsEmPqaIWNjWA==",
            clientId: "abe_dev",
          },
        }
      )
      .then((data) => {
        console.log(data);
        return data;
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function phSetFormData(event) {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  }

  function getPrescriberFirstName() {
    if (formData.PrescriberFirstName?.size > 2) {
      return formData.PrescriberFirstName + "*";
    }
    return formData.PrescriberFirstName;
  }

  function getPrescriberLastName() {
    if (formData.PrescriberLastName?.size > 2) {
      return formData.PrescriberLastName + "*";
    }
    return formData.PrescriberLastName;
  }

  //This method will build out the NPI results from the api rquest
  function populateNppessRecords(responses) {
    //if there are errors Print them and exit
    if (responses.hasOwnProperty("Errors")) {
      console.log(responses.Errors);
      return; //exit if there are errors
    }

    //populate the results
    if (responses.hasOwnProperty("results")) {
      if (responses.results.length > 0) {
        let { validTaxResults, invalidTaxResults } = sortresponses(responses);

        console.log(validTaxResults);
        console.log(invalidTaxResults);

        formData.NppessEntries = [];
        validTaxResults.map((entry) => formData.NppessEntries.push(entry));
        invalidTaxResults.map((entry) => formData.NppessEntries.push(entry));
      }
    }
  }

  function isValidTax(taxonomies) {
    taxonomies.map((taxonomy) => {
      if (ApprovedTaxonomies.some((code) => code.Taxonomy == taxonomy?.code)) {
        return true;
      }
    });
    return false;
  }

  function sortresponses(responses) {
    let validTaxResults = [];
    let invalidTaxResults = [];
    responses.results.map((result) => {
      if (result.hasOwnProperty("taxonomies")) {
        let validTax = isValidTax(result.taxonomies);
        if (validTax) {
          validTaxResults.push(result);
        } else {
          invalidTaxResults.push(result);
        }
      }
    });
    return { validTaxResults, invalidTaxResults };
  }

  function NppesUpdate() {
    formData.NppessEntries = [];
    const nppessUrl = "https://abefunctions.azurewebsites.net/NPPESS";
    axios
      .get(nppessUrl, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          version: "2.1",
          postal_code: formData.PrescriberZipCode,
          first_name: getPrescriberFirstName(),
          last_name: getPrescriberLastName(),
        },
      })
      .then((data) => {
        console.log(data);
        populateNppessRecords(data.data);
        setFormData({ name: "NppessEntries", value: formData.NppessEntries });
        return data;
      })
      .catch((err) => {
        console.log(err);
      });

    setFormData({ name: "NppessEntries", value: formData.NppessEntries });
  }

  const handleChange = (event) => {
    phSetFormData(event);
  };

  const NpiSearchChange = (event) => {
    NppesUpdate();
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key == "Enter") {
      NppesUpdate();
      e.preventDefault();
    }
  };

  const selectNppess = (nppessNum) => {
    setFormData({ name: "PrescriberNPI", value: nppessNum });
  };

  function getAddress(entry) {
    let rtnAddress = "";
    if (entry?.addresses?.[0] !== undefined) {
      let address = entry.addresses[0];
      rtnAddress += address.address_1;
      if (address.address_2 !== undefined && address.address_2 != "") {
        rtnAddress += " " + address.address_2;
      }
      rtnAddress += ", " + address.city;
      rtnAddress += ", " + address.state;
    }

    return rtnAddress;
  }

  function populateNppessTable() {
    if (Array.isArray(formData.NppessEntries)) {
      return (
        <table id="NppessTable" class="table">
          <thead>
            <tr class=" row rowheader">
              <th class="cell">NPI</th>
              <th class="cell">Name</th>
              <th class="cell">Address</th>
            </tr>
          </thead>
          <tbody id="NppessBody">
            {formData.NppessEntries.map((entry, index) => {
              return (
                <tr class="row" key={entry?.number}>
                  <td class="cell">
                    <button onClick={() => selectNppess(entry?.number)}>
                      {entry?.number}
                    </button>
                  </td>
                  <td class="cell">{entry?.basic?.name}</td>
                  <td class="cell">{getAddress(entry)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      );
    }
  }

  return (
    <div className="wrapper">
      <header className="wrapper-header">
        <p />
        <h3>
          Thank you for your interest in learning more about PH-ILD. We're here
          to help
        </h3>
        <p>
          To have a United Therapeutics Representative contact you about PH-ILD,
          please fill out the form below and click Submit
        </p>
        <p>* Required Field</p>
        <form className="wrapper-fields" onSubmit={handleSubmit}>
          <fieldset>
            <label>
              <p>First Name *</p>
              <input
                id="inputbox"
                name="firstName"
                placeholder="First Name"
                onChange={handleChange}
                value={formData.firstName || ""}
                required
              />
            </label>
            <label>
              <p>Last Name *</p>
              <input
                id="inputbox"
                name="LastName"
                placeholder="Last Name"
                onChange={handleChange}
                value={formData.LastName || ""}
                required
              />
            </label>
            <label>
              <p>Prescriber NPI #</p>
              <input
                id="inputbox"
                name="PrescriberNPI"
                placeholder="xxxxxxxxxx"
                type="number"
                onChange={handleChange}
                value={formData.PrescriberNPI || ""}
                required
              />
            </label>
            <h3>NPI Search</h3>
            <div className="PrescriberSearch">
              <label>
                <p>Prescriber First Name</p>
                <input
                  id="inputbox"
                  name="PrescriberFirstName"
                  placeholder=" Prescriber First Name"
                  onChange={handleChange}
                  onKeyPress={handleKeypress}
                  value={formData.PrescriberFirstName || ""}
                />
              </label>

              <label>
                <p>Prescriber Last Name</p>
                <input
                  id="inputbox"
                  name="PrescriberLastName"
                  placeholder="Prescriber Last Name"
                  onChange={handleChange}
                  onKeyPress={handleKeypress}
                  value={formData.PrescriberLastName || ""}
                />
              </label>

              <label>
                <p>Prescriber Zip Code</p>
                <input
                  id="inputbox"
                  name="PrescriberZipCode"
                  placeholder="xxxxx"
                  onChange={handleChange}
                  onKeyPress={handleKeypress}
                  value={formData.PrescriberZipCode || ""}
                />
              </label>
              <button class="searchBtn" onClick={NpiSearchChange}>Search</button>
              {populateNppessTable()}
            </div>
            <label>
              <p>Phone Number *</p>
              <input
                id="inputbox"
                name="PhoneNumber"
                placeholder="xxx-xxx-xxxx"
                onChange={handleChange}
                value={formData.PhoneNumber || ""}
                required
              />
            </label>
            <label>
              <p>Email Address *</p>
              <input
                id="inputbox"
                name="emailAddress"
                placeholder="Email@Address.com"
                type="email"
                onChange={handleChange}
                value={formData.emailAddress || ""}
                required
              />
            </label>
            <label>
              <p>Address</p>
              <input
                id="inputbox"
                name="Address"
                placeholder=""
                onChange={handleChange}
                value={formData.Address || ""}
              />
            </label>
            <label>
              <p>Use my NPI Primary Practice Address</p>
              <input
                id="UsePresriberAddress"
                name="emailAddress"
                type="checkbox"
                onChange={handleChange}
                value={formData.UsePresriberAddress || ""}
              />
            </label>
            <br />
            <br />
            <br />
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6LfYEKIfAAAAAI2PHIxn4PRt5d6Vt2XIQxaEaKrc"
            />
            <br />
            <br />
            <button type="submit">Submit</button>
            <br />
            <br />
            <p>
              United Therapeutics respects your right to have your personal
              information kept private. We may use this information to send you
              materials on our products and services that may be of interest to
              you. We will not share your information with any third parties or
              outside mailing lists.
            </p>
            <p>
              Clicking the Submit button signifies that you have read and agree
              to our &nbsp;
              <a
                href="http://www.unither.com/privacy/privacypolicy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              , and that you agree to receive future communications from United
              Therapeutics Corporation
            </p>
          </fieldset>
        </form>
      </header>
    </div>
  );
}
